header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #060707;
  position: relative;
  --radial-gradient-left: radial-gradient(
		circle at left,
		rgba(6, 7, 7, 0.1),
		rgba(6, 7, 7, 0.9) 65%,
		rgba(6, 7, 7, 0.93) 70%,
		rgba(6, 7, 7, 0.96) 75%,
		rgba(6, 7, 7, 0.99) 85%,
		rgba(6, 7, 7, 1) 90%
	);
  --radial-gradient-right: radial-gradient(
		circle at right,
		rgba(6, 7, 7, 0.1),
		rgba(6, 7, 7, 0.9) 65%,
		rgba(6, 7, 7, 0.93) 70%,
		rgba(6, 7, 7, 0.96) 75%,
		rgba(6, 7, 7, 0.99) 85%,
		rgba(6, 7, 7, 1) 90%
	);
}

header.right-cover {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

header .header-cover {
  position: relative;
  width: 45%;
  min-height: 18rem;
}

header .header-cover .gradient {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--radial-gradient-left);
}

header .header-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

header.right-cover .gradient {
  background: var(--radial-gradient-right);
  float: right;
}

header .header-details {
  padding: 2.5rem;
  color: white;
}

header .header-details .header-title {
  color: #ffa200;
}

header .header-details .header-description {
  color: var(--light);
  font-size: 1.1rem;
}

.articles-hr-bar {
  position: relative;
  z-index: 1;
  height: 4rem;
  background: var(--light-main);
  color: white;
  font-size: 2rem;
  text-align: center;
  padding: 0.5rem;
  -webkit-box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.7);
          box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.7);
}

.main-container {
  background: var(--main);
  color: white;
}

.main-container .article-row {
  padding: 1.5rem;
}

.main-container .article-row .row-title {
  margin-bottom: 1rem;
}

.main-container .article-row .splide__slide {
  text-align: center;
}

.main-container .article-row .splide__slide img {
  cursor: pointer;
  border-radius: 15px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.main-container .article-row .splide__track {
  border-radius: 15px;
}

.main-container .article-row .splide__arrow {
  height: 100%;
  border-radius: 15px;
  background: var(--light-main);
  -webkit-box-shadow: 0 0 10px 0px rgba(20, 20, 20, 0.7);
          box-shadow: 0 0 10px 0px rgba(20, 20, 20, 0.7);
  opacity: 1;
}

.main-container .article-row .splide__arrow svg {
  fill: #c8c8c8;
}

.main-container .article-row .splide__arrow--prev {
  left: -1px;
}

.main-container .article-row .splide__arrow--next {
  right: -1px;
}

.main-article-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  background: white;
  color: #1e1e1e;
  padding: 5rem 3.5rem;
}

.main-article-container .text-container {
  text-align: justify;
  font-size: 1.3rem;
  width: 100%;
}

.main-article-container .text-container .blue-title {
  color: #0e58e2;
}

.main-article-container .text-container .poraka-avtor {
  background: #bbd3ff;
  padding: 1rem;
}

.main-article-container .sidebar {
  float: right;
  background: #1c5185;
  color: white;
  padding: 0 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 30%;
}

.main-article-container .sidebar .promotion-section {
  padding: 1rem 0;
}

.main-article-container .sidebar ul {
  padding-left: 2rem;
}
