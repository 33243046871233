.gift-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 3fr;
      grid-template-columns: 2fr 3fr;
  grid-gap: 1rem;
  background: white;
  width: 60%;
  margin: 4rem auto;
  padding: 1.75rem 2.25rem 1.75rem;
  border-radius: 1rem;
}

.gift-container .book-mockup {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  width: 100%;
}

.gift-container .download-btn {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 65%;
  margin: 1rem auto 0;
}

.gift-container .book-description {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  margin-top: 2rem;
}

@media (max-width: 1000px) {
  .gift-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .gift-container .download-btn {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .gift-container .book-mockup {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    width: 70%;
    margin: 0 auto;
  }
  .gift-container .book-description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .gift-container {
    width: 85%;
  }
  .gift-container .book-mockup {
    width: 100%;
  }
}
