.nav-container #top-navbar {
  z-index: 9999;
  -webkit-transition: background 0.4s ease-out;
  transition: background 0.4s ease-out;
  height: 6rem;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.7);
          box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.7);
}

.nav-container #top-navbar .navbar-brand {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.nav-container #top-navbar .navbar-brand .logo__column1 {
  font-size: calc(2.6rem / 2);
  line-height: 1.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: right;
}

.nav-container #top-navbar .navbar-brand #genijalci {
  line-height: 2.6rem;
  font-size: 2.6rem;
}

.nav-container #top-navbar .btn {
  margin-right: 0.9rem;
}

.nav-container #top-navbar .btn:active, .nav-container #top-navbar .btn:hover {
  color: var(--orange) !important;
}

.nav-container #top-navbar .dropdown:hover .dropdown-menu {
  display: block;
}

.nav-container .nav-colored {
  background: var(--main);
}

.nav-container .nav-transparent {
  background: #0c2743da;
}

.nav-container #search-form * {
  height: 2rem;
}

.nav-container #search-form #search-input {
  border-radius: 0.4rem;
  border-width: 1px 0;
}

.nav-container #categories {
  position: absolute;
  right: 0;
  z-index: 9999;
  min-width: 18rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: none;
  background: var(--main);
  border-radius: 0 0 0 0.75rem;
  -webkit-box-shadow: -10px 10px 30px 0px rgba(0, 0, 0, 0.7);
          box-shadow: -10px 10px 30px 0px rgba(0, 0, 0, 0.7);
}

.nav-container #categories .span-scaled-down {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
}

.nav-container #categories a {
  color: var(--light);
}

.nav-container #categories a:nth-child(odd) h5 {
  background: var(--dark-main);
}

.nav-container #categories a:hover h5 {
  background: var(--light-main);
}

.nav-container #categories h5 {
  padding: 0.3rem 0.5rem 0.3rem 1.2rem;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.nav-container .btn:focus,
.nav-container .btn:active {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: 0px !important;
}

.nav-container .category-visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.2rem 1rem;
}
