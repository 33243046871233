.courses-section {
  background-color: var(--main);
}

.courses-section h1 {
  text-align: center;
  font-size: 2.2rem;
  color: var(--light);
}

.courses-section img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.courses-section .splide__arrow {
  background: var(--light);
  opacity: 1;
  border-radius: 15px;
  height: 100%;
  width: 2.3rem;
}

.courses-section .splide__slide > img {
  border-radius: 15px;
}

.courses-section .splide__arrow--prev {
  left: 0;
}

.courses-section .splide__arrow--next {
  right: 0;
}

.courses-section .splide__pagination {
  -webkit-transform: translate(-50%, 38px);
          transform: translate(-50%, 38px);
}

.courses-section .splide__pagination__page.is-active {
  background: var(--light);
  opacity: 0.9;
}

.courses-section .splide__track {
  cursor: pointer;
  border-radius: 15px;
}

.steps-row {
  row-gap: 3rem;
}

.howto__image {
  margin: 0.5rem auto;
  width: 100%;
  border-radius: 1rem;
}

.howto-section {
  text-align: center;
  padding: 130px 5rem;
  background: var(--windswept-beach);
}

.howto__text {
  display: inline-block;
  font-size: 1.4rem;
  width: 90%;
}

.howto__number {
  background: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
}

.howto__number h2 {
  margin-bottom: 0;
}

.testimonial-section {
  padding: 1rem 15px;
  background-size: cover;
  margin: 0;
  max-width: 100vw;
  position: relative;
}

.testimonial-section .testimonial-section__heading {
  text-align: center;
  color: var(--light);
  padding: 2rem 1.5rem 0 1.5rem;
}

.testimonial-section .testimonial {
  font-size: 19px;
  padding: 0 1rem;
  border-radius: 1rem;
  background: var(--light);
  margin-bottom: 0;
  margin-top: 50px;
  position: relative;
}

.testimonial-section .testimonial span {
  font-size: 21px;
  font-weight: bold;
  display: block;
  padding: 8px 15px 15px 10px;
}

.testimonial-section .testimonial .fa-quote-left {
  font-size: 28px;
  margin-right: 15px;
  line-height: 50px;
}

.testimonial-section .testimonial::after {
  border-left: 30px solid transparent;
  border-top: 30px solid var(--windswept-beach);
  bottom: -30px;
  content: '';
  position: absolute;
  right: 50px;
}

.testimonial-section .testimonial-section__spacer {
  height: 50px;
}

.slogan-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 2rem;
  gap: 0.75rem;
  margin-bottom: 2rem;
  height: calc(100vh - 6rem);
}

@media screen and (max-width: 1000px) {
  .slogan-section {
    padding-right: 3rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 2rem;
  }
}

.slogan-section .col1 {
  padding-left: 5rem;
  color: var(--warning);
}

.slogan-section .col1 .slogan-part-1 {
  font-weight: bold;
  font-size: 3.75rem;
}

.slogan-section .col1 .slogan-part-2 {
  font-size: 3rem;
}

.slogan-section .col1 hr {
  border: 0.15rem solid var(--light-main);
}

.slogan-section .col1 .description {
  font-size: 1.5rem;
  color: var(--orange);
}

.slogan-section .col1 .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.slogan-section .col1 .buttons .btn {
  font-size: 1.3rem;
}

.slogan-section .col2 {
  min-width: 30%;
  max-width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  height: calc(100vh - 9rem);
  margin-top: auto;
}

@media screen and (max-width: 1000px) {
  .slogan-section .col2 {
    display: none;
  }
}

.slogan-section .col2 .splide__track,
.slogan-section .col2 .splide__list,
.slogan-section .col2 .splide__slide,
.slogan-section .col2 img {
  width: 100%;
}
