:root {
  --plus-light-main: rgb(14, 88, 226);
  --light-main: rgb(15, 58, 102);
  --main: rgb(12, 39, 67);
  --dark-main: rgb(10, 28, 46);
  --windswept-beach: rgb(228, 228, 228);
  font-size: 0.95rem;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Balsamiq Sans', sans-serif;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-filter: saturate(50%) contrast(80%) brightness(25%);
          filter: saturate(50%) contrast(80%) brightness(25%);
  z-index: -1;
  background: url("assets/img/books.png") no-repeat center center fixed;
  background-size: cover;
}

.text-darker-warning {
  color: #ffae00;
}

#root {
  height: 100%;
  padding-bottom: 2em;
}
