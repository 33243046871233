.course-dropdown .dropdown-toggle::after {
  display: none;
}

.course-dropdown .dropdown-menu {
  padding: 0;
  overflow: hidden;
}

.course-dropdown .dropdown-menu .fas {
  text-align: center;
  width: 2.3rem;
  height: 2.3rem;
  padding-top: 0.6rem;
  margin-right: 0.4rem;
  padding: auto;
  color: var(--light);
}

.course-dropdown .dropdown-menu .dropdown-item {
  padding: 0 1rem 0 0;
}

.course-dropdown .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: solid #dcdcdc 1px;
}

.course-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #dcdcdc;
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(1) > .fas {
  background: var(--red);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(2) > .fas {
  background: var(--orange);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(3) > .fas {
  background: var(--yellow);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(4) > .fas {
  background: var(--green);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(5) > .fas {
  background: var(--info);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(6) > .fas {
  background: var(--primary);
}

.course-dropdown .dropdown-menu .dropdown-item:nth-child(7) > .fas {
  background: var(--purple);
}
