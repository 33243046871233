.signup-form {
  padding: 1rem;
  border-radius: 1rem;
}

.signup-form .form-control {
  width: 100%;
}

.signup-form .loadingState {
  display: inline-block;
  margin-left: 1.5rem;
  height: 2rem;
}

.signup-form .loadingState .loadingGIF {
  height: 100%;
}

.signup-form .loadingState i {
  vertical-align: middle;
  font-size: 2rem;
}

.signup-form .errorMsg {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--danger);
}
